var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pp-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var disabled = ref.disabled;
return [_c('hr'),_c('pp-card-body',[_c('pp-simple-data',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_vm._v("Input")]},proxy:true},{key:"right",fn:function(){return [_c('div',{staticClass:"text-sm flex items-center"},[_c('span',{staticClass:"text-water-600"},[_vm._v("Balance:")]),(_vm.tokenBalance.is('loading'))?_c('pp-skeleton-loader',{staticClass:"w-16 inline-block ml-1"}):_vm._e(),(_vm.tokenBalance.is('resolved'))?_c('span',{staticClass:"ml-1 font-medium",attrs:{"title":_vm.tokenBalance.response.formattedAmount(),"data-test":"balance"},domProps:{"textContent":_vm._s(new _vm.BigNumberjs(_vm.tokenBalance.response.formattedAmount()).toFormat())}}):_vm._e()],1)]},proxy:true}],null,true)}),_c('PercentageSliderInput',{attrs:{"disabled":!_vm.tokenBalance.is('resolved') ||
          (_vm.tokenBalance.response && _vm.tokenBalance.response.formattedAmount() == 0)},on:{"change":_vm.handleChange},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=$$v},expression:"percentage"}})],1),(_vm.tokenOutputAmounts.is('resolved'))?[_c('hr'),_c('pp-card-body',{staticClass:"space-y-4"},_vm._l((_vm.tokenOutputAmounts.response.tokenAmounts),function(tokenAmount){return _c('OutputTokenAmount',{key:tokenAmount.token.address,attrs:{"data-test":"output-amount","address":tokenAmount.token.address,"amount":tokenAmount.formattedAmount()}})}),1)]:_vm._e(),_c('pp-card-actions',[_c('pp-token-approve-container',{attrs:{"spending-amount":_vm.spendingAmount,"spender-address":_vm.market.spenderAddress},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var approved = ref.approved;
return [(approved)?_c('pp-btn',{staticClass:"flex w-full",attrs:{"size":"xl","type":"submit","data-test":"btn","disabled":disabled ||
              _vm.percentage == 0 ||
              _vm.tokenBalance.is('loading') ||
              _vm.tokenBalance.response.formattedAmount() == 0,"loading":_vm.tokenOutputAmounts.is('loading') || _vm.form.is('loading')}},[_vm._v(" Remove Liquidity ")]):_vm._e()]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }