<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot="{ disabled }">
      <hr />

      <pp-card-body>
        <pp-simple-data>
          <template #left>Input</template>

          <template #right>
            <div class="text-sm flex items-center">
              <span class="text-water-600">Balance:</span>
              <pp-skeleton-loader
                v-if="tokenBalance.is('loading')"
                class="w-16 inline-block ml-1"
              ></pp-skeleton-loader>

              <span
                v-if="tokenBalance.is('resolved')"
                class="ml-1 font-medium"
                :title="tokenBalance.response.formattedAmount()"
                v-text="new BigNumberjs(tokenBalance.response.formattedAmount()).toFormat()"
                data-test="balance"
              ></span>
            </div>
          </template>
        </pp-simple-data>
        <PercentageSliderInput
          v-model="percentage"
          @change="handleChange"
          :disabled="
            !tokenBalance.is('resolved') ||
            (tokenBalance.response && tokenBalance.response.formattedAmount() == 0)
          "
        />
      </pp-card-body>

      <template v-if="tokenOutputAmounts.is('resolved')">
        <hr />

        <pp-card-body class="space-y-4">
          <OutputTokenAmount
            data-test="output-amount"
            v-for="tokenAmount in tokenOutputAmounts.response.tokenAmounts"
            :key="tokenAmount.token.address"
            :address="tokenAmount.token.address"
            :amount="tokenAmount.formattedAmount()"
          />
        </pp-card-body>
      </template>

      <pp-card-actions>
        <pp-token-approve-container
          :spending-amount="spendingAmount"
          :spender-address="market.spenderAddress"
        >
          <template v-slot="{ approved }">
            <pp-btn
              v-if="approved"
              size="xl"
              class="flex w-full"
              type="submit"
              data-test="btn"
              :disabled="
                disabled ||
                percentage == 0 ||
                tokenBalance.is('loading') ||
                tokenBalance.response.formattedAmount() == 0
              "
              :loading="tokenOutputAmounts.is('loading') || form.is('loading')"
            >
              Remove Liquidity
            </pp-btn>
          </template>
        </pp-token-approve-container>
      </pp-card-actions>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'
  import PercentageSliderInput from './PercentageSliderInput.vue'
  import OutputTokenAmount from '../OutputTokenAmount.vue'
  import BigNumberjs from 'bignumber.js'
  import TokenAmount from '@/domains/entities/TokenAmount'

  export default Vue.extend({
    components: { PercentageSliderInput, OutputTokenAmount },
    props: {
      market: { type: Object, required: true },
    },
    data() {
      return {
        percentage: 0,
        timeout: null,
        interval: null,
        tokenBalance: createState({ response: new TokenAmount(this.$props.market.token, 0) }),
        tokenOutputAmounts: createState(),
        form: createState(),
      }
    },
    computed: {
      spendingAmount() {
        return new TokenAmount(
          this.market.token,
          new BigNumberjs(this.tokenBalance.response.formattedAmount())
            .multipliedBy(new BigNumberjs(this.percentage / 100))
            .toString(),
          false
        )
      },
    },
    methods: {
      BigNumberjs,
      fetchTokenBalance() {
        new PromiseHandler(
          () =>
            this.market.token
              .contract(this.$store.getters['wallet/identity'])
              .balanceOf(this.$store.state.wallet.address),
          this.tokenBalance
        ).execute()
      },
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.market.contract(this.$store.getters['wallet/identity']).removeDual({
              percentage: this.percentage,
              slippage: this.$app.state.slippage,
            }),
          this.form
        )
          .execute()
          .then((response) => {
            const output = this.tokenOutputAmounts.response.tokenAmounts.map((token) => ({
              amount: token.formattedAmount(),
              name: TokenAmount.fromSdkTokenAmount(token).token.name,
            }))
            this.$notification.success({
              title: 'Remove liquidity',
              text: `Removed ${formatNumber(output[0].amount)} ${output[0].name} and ${formatNumber(
                output[1].amount
              )} ${output[1].name}.`,
              action: {
                url: generateEtherscanTxUrl(response.hash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch(() => {
            this.$notification.error({
              title: 'Remove liquidity',
              text: `Unable to remove liquidity from ${this.market.token.name}`,
            })
          })
      },
      handleChange() {
        new PromiseHandler(
          () =>
            this.market.contract(this.$store.getters['wallet/identity']).removeDualDetails({
              percentage: this.percentage,
              slippage: this.$app.state.slippage,
            }),
          this.tokenOutputAmounts
        )
          .execute({ force: true })
          .then(() => {
            this.interval = setInterval(() => this.handleChange(), 60000)
          })
      },
    },
    created() {
      this.fetchTokenBalance()
    },
  })
</script>
