<template>
  <RemoveDualAssetsForm :market="market" :key="successCount" @success="successCount++" />
</template>

<script>
  import Vue from 'vue'
  import RemoveDualAssetsForm from '@/components/forms/RemoveDualAssetsForm.vue'

  export default Vue.extend({
    components: { RemoveDualAssetsForm },
    data() {
      return {
        successCount: 0,
      }
    },
    inject: ['market'],
  })
</script>
