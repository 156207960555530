






































import Vue from 'vue'
export default Vue.extend({
  props: {
    value: { type: [Number, String], default: 0 },
    disabled: { type: Boolean, default: false },
    min: { type: [Number, String], default: 1 },
    max: { type: [Number, String], default: 100 },
    step: { type: [Number, String], default: 1 },
  },
})
