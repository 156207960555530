<template>
  <div>
    <div class="text-3xl font-medium">
      <pp-formatted-number
        :value="value"
        :min-decimal="0"
        :max-decimal="0"
        data-test="slider-percentage-number"
      ></pp-formatted-number>
      <span>%</span>
    </div>

    <SliderInput
      class="mt-2"
      :value="value"
      @input="$emit('input', $event)"
      @change="handleChange"
      :disabled="disabled"
    />

    <div class="flex space-x-1 mt-4">
      <pp-tab
        v-for="option in options"
        @click="
          () => {
            $emit('input', option)
            $emit('change', option)
          }
        "
        :disabled="disabled"
        :key="option"
        v-text="`${option}%`"
        :active="option == value"
        :data-test="`remove-${option}%`"
        variant="accent"
      ></pp-tab>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import SliderInput from '@/components/forms/SliderInput.vue'

  export default Vue.extend({
    components: {
      SliderInput,
    },
    props: {
      value: { type: [String, Number], default: 50 },
      disabled: { type: Boolean, default: false },
    },
    data() {
      return {
        options: [25, 50, 75, 100],
      }
    },
    methods: {
      handleChange(event) {
        this.$emit('change', event)
      },
    },
  })
</script>
